$primary: #F67C09;
$primary-light: #FFAD45;
$primary-dark: #E16107;
$primary-light-hover: #F5A83F; // ???

$secondary: #5C7AFF;
$secondary-light: #E7E8FF;
$secondary-dark: #004FCB;

$secondary-hover: #3358FF;
$white: #FFFCFA;
$dirty-white: #FAF8F7;

$gray-2: #CFC9C9;
$gray-3: #CDCBCB;
$gray-4: #8C8989;
$gray-5: #676565;
$gray-6: #3D3C3C;

$green: #599712;

$orange-light: #FFAD45;

$danger: #CE2B37;
$success: #34B157;

$h1: 2.75rem;
$h2: 2rem;
$h3: 1.75rem;
$h4: 1.5rem;
$h5: 1.375rem;
$h6: 1.25rem;
$h7: 1.125rem;
$h8: 1rem;
$body-regular: 16px;
$body-small: 14px;
$small: 12px;


$bg-primary-soft: rgba(237, 201, 175, 0.12);
$bg-primary-softer: rgba(237, 201, 175, 1);

$bg-secondary-soft: rgba(13, 110, 253, 0.08);
$bg-blue-gradient: linear-gradient(to right, rgba(13, 110, 253, 0.20) 10%, rgba(13, 110, 253, 0.08) 100%);

$bg-color: $dirty-white;
$card-bg-color: #FFFCFA;

// Typography
$text-default: #4D4B4B;
$line-height: 120%;

// Input
$input-color: $gray-6;
$input-border-color: #8C8989;
$input-border-focus: $primary;

$input-border-color-valid: $gray-5;

// Card
$card-border-radius: 8px;
$card-padding: 12px;

// Topbar
$topbar-height: 40px;
$topbar-menu-height: 48px;
$topbar-bg: #F67C09;
$topbar-step-bg: white;
$box-shadow: 0px 10px 17px rgba(191, 191, 192, 0.15);
$progression-color: #ADADAD;
$progression-color-completed: $primary;
$progression-text-active: #5C5A5A;


//$input-border-focus-filter: drop-shadow(-2px 2px 5px rgba(246, 124, 9, 0.5)) drop-shadow(2px -2px 5px rgba(246, 124, 9, 0.5));
$input-border-focus-filter: 0 0 5px 4px rgba(246, 124, 9, 0.5);

$sticky-button-height: 100px;

$linkedin: #0E76A8;

$box-widths: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95);
